@import "variables";

@font-face {
  font-family: "TheSansArab-Black";
  src: url("../fonts/TheSansArab-Black.woff ") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "TheSansArab-Bold";
  src: url("../fonts/TheSansArab-Bold.woff ") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "TheSansArab-Plain";
  src: url("../fonts/TheSansArab-Plain.woff ") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "TheSansArab-Light";
  src: url("../fonts/TheSansArab-Light.woff ") format("woff");
  font-weight: normal;
  font-style: normal;
}

* {
  font-family: "TheSansArab-Plain" !important;
}

.stat {
  border: 2px solid $secondary-color;
  border-radius: 10px;
  padding: 10px 20px;
  direction: rtl;
}

.wrapper {
  margin-top: 30px;
  margin-bottom: 50px;
  padding: 20px;
  border: 1px solid $primary-color;
  border-radius: 10px;
  box-shadow: 0 0 5px 1px rgba($color: #000000, $alpha: 0.05);
}

.edit-icon {
  width: 20px;
  fill: $secondary-color;
}

.trash-icon {
  width: 20px;
  fill: red;
}

.custom-icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.custom-md-icon {
  width: 25px;
  height: 25px;
  cursor: pointer;
}
.custom-sm-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.course-edit-img {
  width: 60px;
  height: 60px;
  background-color: lightgray;
  border-radius: 5px;
  overflow: hidden;

  img {
    width: 60px;
    height: 60px;
    object-fit: cover;
  }
}
.feedback-option-input {
  border: none;
}
.feedback-option-box {
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid $primary-color;
}
.modal-dialog {
  overflow: hidden;
  border: none !important;
  border-radius: 10px;

  .detail-modal-header {
    background-color: $secondary-color;
    color: #fff;
    padding: 20px;
    text-align: center;
  }

  .detail-feedback-modal-main {
    padding: 40px 20px;
  }
  .answer-detail-feedback {
    color: $primary-color;
  }
}
