@import 'variables';

.admin-login-wrapper {
    height: 100vh;
    background: linear-gradient(#8e46cd, #a165d5);

    .login-form {
        background: white;
        padding: 30px;
        border-radius: 10px;
        box-shadow: 0px 0px 5px 3px rgb(0 0 0 / 12%);
        margin-top: 30px;
    }
}


.btn-login {
    background-color: white !important;
    border: 1px solid transparent !important;
    box-shadow: 0px 7px 10px 3px rgb(0 0 0 / 16%);
    border-radius: 10px !important;
    color: $secondary-color !important;
    padding: 10px 0;
    font-size: 14px;
    font-weight: bold !important;
    width: 100px;

}

.btn-login:hover {
    box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.1);
}


.breadcrumb-item {
    border-radius: 10px;
    box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.12);
    padding: 20px 30px
}

.modal {


    label {
        color: #054437;
    }


    .admin-green-btn {
        background-color: #054437 !important;
        color: white !important;
        font-size: 14px;
        font-weight: bold;
        padding: 10px 20px;
        border: 2px solid white;
        border-radius: 15px;
        transition: 0.2s;
    }

    .admin-green-btn:hover {
        border: 2px solid white;
    }


    .green-boreder {
        border: 1px solid #054437;
    }

    .admin-grey-btn {
        background-color: #6c757d !important;
        color: white !important;
        font-size: 14px;
        font-weight: bold;
        padding: 10px 20px;
        border: 2px solid white;
        border-radius: 15px;
        transition: 0.2s;
    }

    .admin-grey-btn:hover {
        border: 2px solid white;
    }

    input {
        border: 1px solid #054437;
    }

}

.data-download-btn {
    .fade-in {
        opacity: 0;
        transition: opacity 1s ease-in-out;
    }

    .fade-in.visible {
        opacity: 1;
    }

    .progress-bar {
        height: 10px;
        border-radius: 50px;

        span {
            background-color: #8e46cd;
        }
    }
}