@import "variables";

a {
  text-decoration: none !important;
}

.text-primary {
  color: $primary-color !important;
  font-family: "TheSansArab-Plain";
}

.text-secondary {
  color: $secondary-color !important;
  font-family: "TheSansArab-Plain";
}

.text-white {
  color: #fff !important;
}

.bg-primary {
  background-color: $primary-color !important;
}

.bg-secondary {
  background-color: $secondary-color !important;
}

.text-bold {
  font-weight: bold;
}

.cursor-pointer {
  cursor: pointer !important;
}

.form-control {
  height: 50px;
}

.input-border-secondary {
  height: 50px;
  border: 1px solid $secondary-color !important;
}

.input-border-primary {
  height: 50px;
  border: 1px solid $primary-color !important;
}

.custom-btn {
  border: 1px solid $secondary-color !important;
  border-radius: 10px !important;
  font-size: 14px;
  font-weight: bold !important;
  width: fit-content;
  padding: 10px;
  box-shadow: 0 0 10px 0px rgb(0 0 0 / 5%);
  border-radius: 10px !important;
  transition: 0.2s;

  &:hover {
    box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.16);
  }

  &.inactive-btn {
    background-color: white !important;
    color: $secondary-color !important;
  }

  &.active-btn {
    background-color: $secondary-color !important;
    color: white !important;
  }

  &.no-btn {
    border: 1px solid red !important;
    background-color: white;
    color: red;
  }
  &:disabled {
    background-color: grey !important;
  }
}

.ql-editor {
  height: 150px;
  direction: rtl;
  text-align: right;
}

.cursor-pointer {
  cursor: pointer !important;
}

.question-form {
  border-radius: 10px;
  background-color: white;
  box-shadow: 1px 1px 4px 1px rgb(0 0 0 / 23%);
  margin-bottom: 20px;
  margin-right: 10px;
  padding: 20px;
  width: 90%;
}
// .ql-error {
//   border: 1px solid red;
// }
.attendance-card {
  border: 1px solid grey;
  padding: 10px 20px;
  border-radius: 10px;
}
